import * as React from 'react';
import { Checkbox, Radio, HTMLSelect } from '@blueprintjs/core';
import { css } from 'aphrodite';

import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import './FormControls.css';
import { kebabCase } from 'lodash';
import { useTypedSelector } from 'shared/utils';

interface LabelProps {
  error?: object;
  children?: React.ReactNode;
  className?: string;
  labelStyle?: React.CSSProperties;
  id?: string;
  style?: object;
  htmlFor?: any;
  props?: any;
}
export const Label = ({
  error,
  children,
  className,
  labelStyle,
  id,
  ...props
}: LabelProps) => {
  return (
    <label style={labelStyle} id={id} className={className} {...props}>
      {children}
    </label>
  );
};

export const FormSection = ({ children }: { children?: React.ReactNode }) => (
  <div className="form-section">{children}</div>
);

export const RadioButtonInput = ({
  fill,
  buttonStyle,
  children,
}: {
  fill: string;
  buttonStyle?: React.CSSProperties;
  children?: React.ReactNode;
}) => (
  <div
    className={
      fill === 'true'
        ? 'radio-button-input radio-button-input--fill'
        : 'radio-button-input'
    }
    style={buttonStyle}
  >
    {children}
  </div>
);

interface SmallRadioButtonInputFormikProps {
  field: any;
  form: any;
  id: string;
  label: string;
  props?: any;
  smallButtonStyle?: React.CSSProperties;
  extraStyle?: any;
}
export const SmallRadioButtonInput = ({
  smallButtonStyle,
  children,
}: {
  smallButtonStyle: React.CSSProperties;
  children?: React.ReactNode;
}) => (
  <div className={'radio-button-input-small'} style={smallButtonStyle}>
    {children}
  </div>
);

interface InputGroupProps {
  label?: string;
  required?: boolean;
  name?: string;
  children?: React.ReactNode;
  inputGroupStyle?: React.CSSProperties;
}
export const InputGroup = ({
  children,
  label,
  name,
  inputGroupStyle,
  required,
}: InputGroupProps) => (
  <div className="input-group" style={inputGroupStyle}>
    {label && (
      <Label id={kebabCase(name)} className={required ? 'required' : ''}>
        {label}
      </Label>
    )}
    {children}
  </div>
);

interface TextInputFormikProps {
  field: any;
  form: any;
  type: string;
  className?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  props?: any;
  textInputStyle?: {
    label: React.CSSProperties;
    input: React.CSSProperties;
  };
}
export const TextInputFormik = ({
  field: { name, ...field },
  form: { touched, errors },
  type = 'text',
  className,
  textInputStyle,
  label,
  placeholder = '',
  required,
  ...props
}: TextInputFormikProps) => {
  const error = errors[name];
  const touch = touched[name];
  return (
    <InputGroup inputGroupStyle={{ flexGrow: 1, flexBasis: 0 }}>
      {label && (
        <Label
          htmlFor={name}
          error={error}
          className={required ? 'required' : ''}
          labelStyle={textInputStyle?.label}
          id={`${name}-label`}
        >
          {label}
        </Label>
      )}
      {type === 'textarea' ? (
        <textarea
          {...field}
          {...props}
          id={name}
          type={type}
          rows="5"
          style={textInputStyle?.input}
          placeholder={placeholder}
          className={
            error && touch ? 'text-input text-input--error' : 'text-input'
          }
        />
      ) : (
        <input
          {...field}
          {...props}
          id={name}
          type={type}
          style={textInputStyle?.input}
          placeholder={placeholder}
          className={
            error && touch ? 'text-input text-input--error' : 'text-input'
          }
        />
      )}
      {touch && error && <div className="input-feedback">{error}</div>}
    </InputGroup>
  );
};

interface ToggleInputFormikProps {
  id: string;
  field: any;
  form: any;
  label: string;
  required: boolean;
  checkboxStyle: {
    container: React.CSSProperties;
    label: React.CSSProperties;
  };
}
export const ToggleInputFormik = ({
  field: { name, ...field },
  form: { values },
  id,
  label,
  required,
  checkboxStyle,
  ...props
}: ToggleInputFormikProps) => {
  return (
    <InputGroup inputGroupStyle={checkboxStyle?.container}>
      <Checkbox
        {...field}
        {...props}
        id={name}
        checked={values[name]}
        alignIndicator="left"
        inline
        large
      >
        <Label htmlFor={name} style={checkboxStyle?.label} id={`${name}-label`}>
          {label}
        </Label>
      </Checkbox>
    </InputGroup>
  );
};

interface RadioInputFormikProps {
  id: string;
  field: any;
  form: any;
  label: string;
  props?: any;
}
export const RadioInputFormik = ({
  field: { name, ...field },
  form: { values },
  id,
  label,
  ...props
}: RadioInputFormikProps) => {
  return (
    <Radio
      {...field}
      {...props}
      label={label}
      id={`${name}[${id}]`}
      type="radio"
      name={name}
      value={id}
      checked={values[name] === id}
      inline
    />
  );
};

interface RadioButtonGroupProps {
  name: string;
  errors?: object;
  children?: React.ReactNode;
  message?: string;
  label?: string;
}
export const RadioButtonGroup = ({
  name,
  errors = {},
  children,
  message,
  label,
}: RadioButtonGroupProps) => {
  const error = errors[name];
  return (
    <InputGroup>
      {label && <Label>{label}</Label>}
      <div className="radio-button-group">{children}</div>
      {message && (
        <h4 id={kebabCase(message)} className="input-group-message">
          {message}
        </h4>
      )}
      {error && <div className="input-feedback">{error}</div>}
    </InputGroup>
  );
};

export const RadioActionButtonInputFormik = ({
  field: { name, ...field },
  form: { values },
  id,
  label,
  iconName,
  ...props
}: {
  field: any;
  form: any;
  id: string;
  label: string;
  iconName: string;
  props?: any;
}) => {
  const isActive = values[name] === id;
  return (
    <ActionButton isActive={isActive}>
      <Label htmlFor={`${name}[${id}]`}>
        <input
          {...field}
          {...props}
          id={`${name}[${id}]`}
          type="radio"
          name={name}
          value={id}
          checked={values[name] === id}
          style={{ display: 'none' }}
        />
        {iconName && (
          <ActionButton.Icon>
            <Icon name={iconName} color={isActive && '#fff'} />
          </ActionButton.Icon>
        )}
        <ActionButton.Text>{label}</ActionButton.Text>
      </Label>
    </ActionButton>
  );
};

interface RadioImageInputFormikProps {
  field: any;
  form: any;
  id: string;
  label: string;
  imgSrc: string;
  props?: any;
}
export const RadioImageInputFormik = ({
  field: { name, ...field },
  form: { values },
  id,
  label,
  imgSrc,
  ...props
}: RadioImageInputFormikProps) => {
  const isActive = values[name] === id;
  const style = isActive
    ? {
        boxShadow: '0 0 0 3px rgba(76, 121, 188, .45)',
        transform: 'scale(1)',
        position: 'relative',
        zIndex: '1',
        transition: '.2s ease-out',
      }
    : {
        opacity: '.5',
        transform: 'scale(.9)',
        transition: '.2s ease-out',
      };
  return (
    <Label htmlFor={`${name}[${id}]`} style={style}>
      <input
        {...field}
        {...props}
        id={`${name}[${id}]`}
        type="radio"
        name={name}
        value={id}
        checked={values[name] === id}
        style={{ display: 'none' }}
      />
      {imgSrc && <img src={imgSrc} alt="" />}
      <ActionButton.Text>{label}</ActionButton.Text>
    </Label>
  );
};

interface RadioButtonInputFormikProps {
  field: any;
  form: any;
  id: string;
  label: string;
  fill: string;
  props?: any;
  smallButtonStyle?: React.CSSProperties;
}
export const RadioButtonInputFormik = ({
  field: { name, ...field },
  form: { values },
  id,
  label,
  smallButtonStyle,
  fill,
  ...props
}: RadioButtonInputFormikProps) => {
  return (
    <RadioButtonInput fill={fill} buttonStyle={{ flex: 0 }}>
      <Label
        htmlFor={`${name}[${id}]`}
        className={
          values[name] === id
            ? 'radio-button-label radio-button-label--selected'
            : 'radio-button-label'
        }
        labelStyle={smallButtonStyle}
      >
        <Radio
          {...field}
          {...props}
          label={label}
          id={`${name}[${id}]`}
          type="radio"
          name={name}
          value={id}
          checked={values[name] === id}
          inline
          style={{ display: 'none' }}
        />
        {label}
      </Label>
    </RadioButtonInput>
  );
};

export const SmallRadioButtonInputFormik = ({
  field: { name, ...field },
  form: { values },
  id,
  label,
  smallButtonStyle,
  extraStyle,
  ...props
}: SmallRadioButtonInputFormikProps) => {
  return (
    <SmallRadioButtonInput smallButtonStyle={smallButtonStyle ?? {}}>
      <Label
        htmlFor={`${name}[${id}]`}
        id={id}
        className={`
        ${css(extraStyle)}
        ${
          values[name] === id
            ? 'radio-button-label radio-button-label--selected'
            : 'radio-button-label'
        }
        `}
        labelStyle={smallButtonStyle}
      >
        <Radio
          {...field}
          {...props}
          label={label}
          id={`${name}[${id}]`}
          type="radio"
          name={name}
          value={id}
          checked={values[name] === id}
          inline
          style={{ display: 'none' }}
        />
        {label}
      </Label>
    </SmallRadioButtonInput>
  );
};

interface SelectInputFormikProps {
  field: any;
  form: any;
  id: string;
  label: string;
  options: Record<string, any>[];
  required: boolean;
  props?: any;
  selectInputStyle?: {
    label: React.CSSProperties;
    input: React.CSSProperties;
  };
}
export const SelectInputFormik = ({
  field: { name, ...field },
  form: { touched, errors },
  id,
  label,
  options,
  required,
  selectInputStyle,
  ...props
}: SelectInputFormikProps) => {
  const error = errors[name];
  const touch = touched[name];
  const {
    donationTemplateSchema: {
      donationTemplate: {
        donationFormMainContent: { formSelectOptionLabel },
      },
    },
  } = useTypedSelector(state => state.donationTemplateSchema);

  return (
    <InputGroup inputGroupStyle={{ flexGrow: 1, flexBasis: 0 }}>
      {label && (
        <Label
          htmlFor={name}
          error={error}
          labelStyle={selectInputStyle?.label}
          className={required ? 'required' : ''}
          id={`${name}-label`}
        >
          {label}
        </Label>
      )}
      <HTMLSelect
        {...field}
        {...props}
        iconProps={{ icon: 'chevron-down' }}
        name={name}
        id={name}
        fill
        large
        minimal
      >
        <option disabled value="">
          {' '}
          {formSelectOptionLabel}{' '}
        </option>
        {Object.keys(options).map(key => {
          const [optionId, optionValue] = Object.values(options[key]);
          return (
            <option
              key={optionId as string}
              value={optionId as string}
              id={`${kebabCase(optionValue as string)}-option`}
            >
              {optionValue}
            </option>
          );
        })}
      </HTMLSelect>
      {touch && error && <div className="input-feedback">{error}</div>}
    </InputGroup>
  );
};
